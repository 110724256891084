<template>
  <div>
    <filtered-bar @filterbar_data="filterbar_data" @pie_data="pie_data">
    </filtered-bar>
    <b-card>
      <div v-for="(x, index) of progress_bar_rows" :key="index" class="mb-1">
        <div class="d-flex justify-content-between">
          <span>{{ x.name }} ตัวที่ {{ x.number }}</span>
          <span>{{ x.total_rental_price_focus | number }}฿</span>
        </div>
        <b-progress :value="x.total_duration" :max="x.max" height="6px" />
      </div>
    </b-card>
    <b-card style="background-color: white" v-if="pie_data">
      <vue-apex-charts
        type="donut"
        height="350"
        :options="donut_option"
        :series="apexChatData.donutChart.series"
      />
    </b-card>
  </div>
</template>

<script>
import { BProgress, BCard } from "bootstrap-vue";
import FilteredBar from "./component/FilteredBar.vue";
import VueApexCharts from "vue-apexcharts";
import _ from "lodash";
import apexChatData from "./component/apexChartData";

export default {
  components: {
    FilteredBar,
    BProgress,
    BCard,
    VueApexCharts,
  },
  data() {
    return {
      apexChatData,
      progress_bar_rows: [],
      pie_chart_data: null,
      donut_option: {},
    };
  },
  created() {
    this.donut_option = JSON.parse(
      JSON.stringify(apexChatData.donutChart.chartOptions)
    );
  },
  methods: {
    filterbar_data(x) {
      this.progress_bar_rows = x.rows;
    },
    pie_data(x) {
      this.donut_option = {
        ...this.donut_option,
        labels: x.labels,
      };

      this.apexChatData.donutChart.series = x.data;
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>
